import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import Button from "@mui/material/Button";
import url from "../../url/fetchUrl";
import "./NewMenuCard.scss";

const NewMenuCard = () => {
  const [categoryOption, setCategoryOption] = useState([]);

  const params = useParams();

  const [form, setForm] = useState({
    categoryName: "",
    title: "",
    description: "",
    number: 0,
  });

  const [menuOption, setMenuOption] = useState([]);

  const [contentsValue, setContentsValue] = useState("");

  useEffect(() => {
    fetch(`${url}/projectInfo/${params.projectName}`)
      .then((res) => res.json())
      .then((data) => {
        let categoryDate = data.data.data;

        let categoryDateArr = [];

        categoryDate.map((com) =>
          com.menu.map((com2) =>
            com2.menu.length === 0
              ? categoryDateArr.push(com2.title)
              : com2.menu.map((com3) => categoryDateArr.push(com3))
          )
        );

        setCategoryOption(categoryDateArr);
      });
  }, [params]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createPost();
  };

  const createPost = () => {
    if (
      form.categoryName.length > 0 &&
      form.title.length > 0 &&
      form.description.length > 0 &&
      form.number > 0
    ) {
      fetch(`${url}/projectManual`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          projectName: params.projectName,
          categoryName: form.categoryName,
          title: form.title,
          description: form.description,
          number: form.number,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success === true) {
            alert("메뉴얼 생성이 완료 되었습니다.");
            window.location.replace(`/newCard/${params.projectName}`);
          }
        });
    } else {
      alert("다 입력하셈");
    }
  };

  useEffect(() => {
    fetch(`${url}/projectManual/`)
      .then((res) => res.json())
      .then((data) => {
        const dataSorting = data.data.sort(function (a, b) {
          return a.number - b.number;
        });

        const projectName = [
          ...new Set(data.data.map((com) => com.projectName)),
        ];

        const projectFilter = projectName.map((com) => {
          return {
            projectName: com,
            projectCard: [],
          };
        });

        projectFilter.map((com) => {
          dataSorting.map((com2) => {
            if (com.projectName === com2.projectName) {
              com.projectCard.push(com2);
            }
          });
        });

        setMenuOption(projectFilter);
      });
  }, [params]);

  const handleMenuCard = (cardInfo) => {
    fetch(`${url}/projectInfo/${params.projectName}`)
      .then((res) => res.json())
      .then((data) => {
        let categoryDate = data.data.data;

        let categoryDateArr = [];

        categoryDate.map((com) =>
          com.menu.map((com2) =>
            com2.menu.length === 0
              ? categoryDateArr.push(com2.title)
              : com2.menu.map((com3) => categoryDateArr.push(com3))
          )
        );
        setCategoryOption(categoryDateArr);
      });

    fetch(`${url}/projectManual/fixedCard/${cardInfo._id}`)
      .then((res) => res.json())
      .then((data) => {
        setForm({
          categoryName: "",
          title: data.data[0].title,
          description: data.data[0].description,
          number: 0,
        });
        setContentsValue(data.data[0].description);
      });
  };

  return (
    <div className="myBlock">
      <div className="selectBoxTotal">
        <div className="selectBox">
          <h3>
            복제하고자 하는 프로젝트 메뉴얼 카드를 선택해주세요.(선택사항)
          </h3>
          {menuOption.length > 0 &&
            menuOption.map((com0, idx0) => {
              return (
                <div key={idx0}>
                  <h4>Project Name: {com0.projectName}</h4>
                  {com0.projectCard.map((com, idx) => {
                    return (
                      <div className="buttonBox" key={idx}>
                        <Button
                          variant="outlined"
                          className="selectButton"
                          onClick={() => handleMenuCard(com)}
                          sx={{
                            color: "black",
                            border: "1px solid rgb(118, 118, 118)",
                            marginBottom: "10px",
                            width: "100%",
                          }}
                        >
                          {com.number && com.number} / {com.categoryName} /{" "}
                          {com.title}
                        </Button>
                      </div>
                    );
                  })}
                </div>
              );
            })}
        </div>
      </div>

      <form className="myBlockForm" onSubmit={handleSubmit}>
        <div className="formBox">
          <label className="form-label">Category</label>
          {categoryOption.length > 0 && (
            <select
              className="formBoxSelect"
              name="categoryName"
              onChange={handleChange}
              value={form.categoryName}
            >
              <option value="" disabled>
                Menu Card를 선택해주세요
              </option>
              {categoryOption.map((com, idx) => {
                return (
                  <option value={com} key={idx}>
                    {com}
                  </option>
                );
              })}
            </select>
          )}
        </div>

        <div className="formBox">
          <label className="form-label">Title</label>
          <input
            className="formBoxInput"
            label="Title"
            placeholder="Title"
            name="title"
            onChange={handleChange}
            value={form.title}
          />
        </div>

        <div className="formBox">
          <label className="form-label">Card Number</label>
          <input
            type="number"
            className="formBoxInput"
            label="Card Number"
            placeholder="Card Number"
            name="number"
            onChange={handleChange}
            value={form.number}
          />
        </div>

        <Editor
          scriptLoading={{ async: true }}
          apiKey="eseq91xmbynr81zspi1o4olm4gmqh7pa4p5f31hlwaslguyj"
          initialValue={contentsValue}
          init={{
            height: 500,
            menubar: false,
            plugins:
              "advlist autolink lists link image charmap preview anchor searchreplace visualblocks code fullscreen insertdatetime media table code help wordcount pagebreak",

            toolbar:
              "undo redo | autolink link | forecolor | fontsize | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | help",

            pagebreak_separator: "<!-- my page break -->",

            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14pt }",
            skin_url: "/assets/libs/tinymce/skins/ui/oxide",
            content_css:
              "/assets/libs/tinymce/skins/content/default/content.min.css", // Static files path(step 2)
          }}
          onEditorChange={(newValue) => {
            setForm({
              ...form,
              description: newValue,
            });
          }}
        />

        <button className="submitButton" type="submit">
          저장하기
        </button>
      </form>
    </div>
  );
};

export default NewMenuCard;
