import React, { useState, useEffect } from "react";
import MainPageCategoryLists from "./MainPageLeftComponets/MainPageCategoryLists";
import "./MainPageLeft.scss";

const MainPageLeft = ({
  sideMenuData,
  projectInfoData,
  setFilterText,
  filterText,
  handleToggleFunction,
  totalContentsControl,
  setTotalContentsControl,
}) => {
  const [sideMenuControllPosition, setSideMenuControllPosition] = useState(0);

  const handletotalContentsControl = (e) => {
    setTotalContentsControl((totalContentsControl) => !totalContentsControl);
    // console.log(totalContentsControl);
    // if (e.target.name === "close") {
    //   setTotalContentsControl(false);
    // } else {
    //   setTotalContentsControl(true);
    // }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY >= 62) {
      setSideMenuControllPosition(window.scrollY - 62);
    }
  };

  return (
    <div className="mainPageLeftContents">
      <div
        className="mainPageLeftContentsBox"
        // style={{ position: "absolute", top: sideMenuControllPosition }}
      >
        
        <img
          className="mainPageClientLogo"
          src={projectInfoData.clientLogo}
          alt="Client Logo"
        />
        <div className="mainPageLeftContentsControlBox">
          <button name="close" onClick={handletotalContentsControl} style={{ marginBottom: "5px", marginTop: "5px" }}>
            {totalContentsControl ? "-" : "+"}
          </button>
          {/* <button name="open" onClick={handletotalContentsControl}>
            +
          </button> */}
        </div>

        <div className="mainPageCategory">
          {sideMenuData.map((com, idx) => {
            return (
              <MainPageCategoryLists
                key={idx}
                menu={com}
                setFilterText={setFilterText}
                filterText={filterText}
                handleToggleFunction={handleToggleFunction}
                totalContentsControl={totalContentsControl}
              />
            );
          })}
        </div>
        
      </div>
    </div>
  );
};

export default MainPageLeft;
