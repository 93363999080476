import React, { useState, useRef, useEffect } from "react";
import url from "../../../../url/fetchUrl";
import "./MainPageContentCard.scss";

const MainPageContentCard = ({ data, page, filterText }) => {
  const [detailToggle, setDetailToggle] = useState(false);
  const [detailToggleCN, setDetailToggleCN] = useState(false);
  const [manualDescription, setManualDescription] = useState("");

  const componentRef = useRef(null);

  const handleDetailToggle = (id) => {
    if (detailToggle) {
      setDetailToggleCN(false);
      setTimeout(() => {
        setDetailToggle(false);
        setManualDescription("");
      }, 150);
    } else {
      fetch(`${url}/projectManual/fixedCard/${id}`)
        .then((res) => res.json())
        .then((data) => setManualDescription(data.data[0].description));
      setDetailToggle(true);
      setDetailToggleCN(true);
    }
  };

  const printMousePos = (event) => {
    if (event.target.src !== undefined) {
      event.target.requestFullscreen();
    }
  };

  function popupExplain(param) {
    var val = param.outerHTML;

    var w = window.open("", "", "");

    var style = "<style>img {width: 100%;}</style>";

    w.document.write(
      '<html><head><title>프린트</title><link rel="stylesheet" href="css/style.css"></head><body>'
    );
    w.document.write(style);
    w.document.write(val);
    w.document.write("</body></html>");
    w.document.close();

    setTimeout(function () {
      w.focus();
      w.print();
    }, 100);
  }

  useEffect(() => {
    setDetailToggleCN(false);
    setDetailToggle(false);
  }, [page, filterText]);

  return (
    <div className="mainPageContentCardBox">
      <div className="mainPageContentCardTop">
        {data.categoryName}
        {detailToggle && (
          <img
            className={detailToggleCN ? "printButton" : "printButton close"}
            src="/images/print-solid.svg"
            alt="print"
            onClick={() => {
              popupExplain(componentRef.current);
            }}
          />
        )}
      </div>
      <div className="mainPageContentCardMid" ref={componentRef}>
        <div className="mainPageContentCardMidTitle" onClick={() => handleDetailToggle(data._id)}>{data.title}</div>
        {detailToggle && (
          <div
            className={
              detailToggleCN
                ? "mainPageContentCardMidDescription"
                : "mainPageContentCardMidDescription close"
            }
            dangerouslySetInnerHTML={{ __html: manualDescription }}
            onClick={printMousePos}
          ></div>
        )}
      </div>
      <div 
        className="mainPageContentCardBottom" 
        onClick={() => handleDetailToggle(data._id)}
      >
        {detailToggle ? "닫기" : "펼치기 "}
      </div>
    </div>
  );
};

export default MainPageContentCard;
