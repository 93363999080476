import React, { useState } from "react";
import MiddleCategory from "./MiddleCategory";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import "./DetailCategoryCard.scss";

const DetailCategoryCard = ({
  idx,
  data,
  categoryDetail,
  setCategoryDetail,
}) => {
  const [tagText, setTagText] = useState("");

  const handleCategoryTitle = (e) => {
    const { name, value } = e.target;
    categoryDetail[idx][name] = value;
    setCategoryDetail(categoryDetail);
    setTagText(value);
  };

  const addCategory = () => {
    const obj = {
      title: "",
      menu: [],
    };
    categoryDetail[idx].menu.push(obj);
    setCategoryDetail([...categoryDetail]);
  };

  const deleteCatedory = (arrIndex) => {
    categoryDetail[idx].menu.splice(arrIndex, 1);
    setCategoryDetail([...categoryDetail]);
  };

  return (
    <div className="detailCategoryCardBox">
      <div>
        <div>
          <FormControl fullWidth>
            <TextField
              id="outlined-basic"
              label={`${idx + 1} Category`}
              variant="outlined"
              name="title"
              onChange={handleCategoryTitle}
              value={data.title}
              sx={{
                marginBottom: "10px",
              }}
            />
          </FormControl>
        </div>

        <div className="subMenuBox">
          <div className="blogTagBox">
            {data.menu.map((tag, index) => {
              return (
                <React.Fragment key={index}>
                  <MiddleCategory
                    tag={tag}
                    idx={idx}
                    index={index}
                    categoryDetail={categoryDetail}
                    setCategoryDetail={setCategoryDetail}
                  />
                  <FormControl fullWidth>
                    <Button
                      variant="outlined"
                      onClick={() => deleteCatedory(index)}
                      sx={{
                        color: "black",
                        border: "1px solid rgb(118, 118, 118)",
                        marginBottom: "10px",
                      }}
                    >
                      서브 메뉴 카테고리 삭제
                    </Button>
                  </FormControl>
                </React.Fragment>
              );
            })}
          </div>
        </div>
        <FormControl fullWidth>
          <Button
            variant="outlined"
            onClick={addCategory}
            sx={{
              color: "black",
              border: "1px solid rgb(118, 118, 118)",
            }}
          >
            서브 메뉴 카테고리 추가
          </Button>
        </FormControl>
      </div>
    </div>
  );
};

export default DetailCategoryCard;
