import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import Button from "@mui/material/Button";
import url from "../../url/fetchUrl";
import "./FixedMenuCard.scss";
import "../../NewMenuCard/NewCard/NewMenuCard.scss";

const FixedMenuCard = () => {
  const params = useParams();

  const [categoryOption, setCategoryOption] = useState([]);
  const [menuOption, setMenuOption] = useState([]);
  const [cardId, setCardId] = useState("");

  const [contentsValue, setContentsValue] = useState("");

  const [form, setForm] = useState({
    categoryName: "",
    title: "",
    description: "",
    number: 0,
  });

  const [deleteModal, setDeleteModal] = useState({
    state: false,
    parameter: "",
  });

  const [deleteBoolean, setDeleteBoolean] = useState(false);

  useEffect(() => {
    fetch(`${url}/projectManual/${params.projectName}`)
      .then((res) => res.json())
      .then((data) => {
        const dataSorting = data.data.sort(function (a, b) {
          return a.number - b.number;
        });

        setMenuOption(dataSorting);
      });
  }, [params]);

  const handleSubmit = (e) => {
    e.preventDefault();
    fixedCard();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleMenuCard = (cardInfo) => {
    setCardId(cardInfo._id);

    fetch(`${url}/projectInfo/${params.projectName}`)
      .then((res) => res.json())
      .then((data) => {
        let categoryDate = data.data.data;

        let categoryDateArr = [];

        categoryDate.map((com) =>
          com.menu.map((com2) =>
            com2.menu.length === 0
              ? categoryDateArr.push(com2.title)
              : com2.menu.map((com3) => categoryDateArr.push(com3))
          )
        );
        setCategoryOption(categoryDateArr);
      });

    fetch(`${url}/projectManual/fixedCard/${cardInfo._id}`)
      .then((res) => res.json())
      .then((data) => {
        setForm({
          categoryName: data.data[0].categoryName,
          title: data.data[0].title,
          description: data.data[0].description,
          number: data.data[0].number,
        });
        setContentsValue(data.data[0].description);
      });
  };

  const fixedCard = () => {
    fetch(`${url}/projectManual/fixedCard/${cardId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(form),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          alert("메뉴얼 수정이 완료됐습니다.");
          window.location.replace(`/fixedCard/${params.projectName}`);
        }
      });
  };

  const deleteCard = (cardData) => {
    setDeleteModal({ ...deleteModal, state: true, parameter: cardData });
  };

  const closeModal = () => {
    setDeleteModal({ ...deleteModal, state: false, parameter: "" });
  };

  const deleteData = (cardData) => {
    fetch(`${url}/projectManual/fixedCard/${cardData._id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          alert("삭제 완료됐습니다.");
          window.location.replace(`/fixedCard/${params.projectName}`);
        }
      });
  };

  const handleDeleteText = (e) => {
    if (e.target.value === "지우면 후회할텐데") {
      setDeleteBoolean(true);
    } else {
      setDeleteBoolean(false);
    }
  };

  return (
    <>
      {deleteModal.state && (
        <div className="deleteModal">
          <div className="deleteModalContent">
            <div className="deleteModalContentTitle">
              정말 삭제 하시겠습니까? <br />
              아래에 "지우면 후회할텐데"를 입력하여 주세요.
            </div>
            <input onChange={handleDeleteText} />
            <div className="deleteModalContentButtonBox">
              <button
                className="deleteModalContentButtonCancle"
                onClick={closeModal}
              >
                취소
              </button>
              <button
                className="deleteModalContentButtonDelete"
                onClick={() => {
                  deleteData(deleteModal.parameter);
                }}
                disabled={!deleteBoolean}
              >
                삭제
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="myBlock">
        <div className="selectBoxTotal">
          <div className="selectBox">
            <div>해당 프로젝트에서 변경하고자하는 메뉴얼을 선택해주세요.</div>
            {menuOption.length > 0 &&
              menuOption.map((com, idx) => {
                return (
                  <div
                    className="buttonBox"
                    key={idx}
                    style={{ justifyContent: "space-between" }}
                  >
                    <Button
                      variant="outlined"
                      className="selectButton"
                      onClick={() => handleMenuCard(com)}
                      sx={{
                        color: "black",
                        border: "1px solid rgb(118, 118, 118)",
                        marginBottom: "10px",
                        width: "80%",
                      }}
                    >
                      {com.number && com.number} / {com.categoryName} /{" "}
                      {com.title}
                    </Button>

                    <Button
                      variant="outlined"
                      className="selectDeleteButton"
                      onClick={() => deleteCard(com)}
                      sx={{
                        color: "black",
                        border: "1px solid rgb(118, 118, 118)",
                        marginBottom: "10px",
                        width: "15%",
                      }}
                    >
                      삭제
                    </Button>
                  </div>
                );
              })}
          </div>
        </div>

        {categoryOption.length > 0 && (
          <form className="myBlockForm" onSubmit={handleSubmit}>
            <div className="formBox">
              <label className="form-label">Category</label>
              <select
                className="formBoxSelect"
                name="categoryName"
                onChange={handleChange}
                value={form.categoryName}
              >
                <option value="" disabled>
                  Menu Card를 선택해주세요
                </option>
                {categoryOption.map((com, idx) => {
                  return (
                    <option value={com} key={idx}>
                      {com}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="formBox">
              <label className="form-label">Title</label>
              <input
                className="formBoxInput"
                label="Title"
                placeholder="Title"
                name="title"
                onChange={handleChange}
                value={form.title}
              />
            </div>

            <div className="formBox">
              <label className="form-label">Card Number</label>
              <input
                type="number"
                className="formBoxInput"
                label="Card Number"
                placeholder="Card Number"
                name="number"
                onChange={handleChange}
                value={form.number}
              />
            </div>

            <Editor
              apiKey="eseq91xmbynr81zspi1o4olm4gmqh7pa4p5f31hlwaslguyj"
              initialValue={contentsValue}
              init={{
                height: 500,
                menubar: false,
                plugins:
                  "advlist autolink lists link image charmap preview anchor searchreplace visualblocks code fullscreen insertdatetime media table code help wordcount ",

                toolbar:
                  "undo redo | autolink link | forecolor | fontsize | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | help",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14pt }",
                skin_url: "/assets/libs/tinymce/skins/ui/oxide",
                content_css:
                  "/assets/libs/tinymce/skins/content/default/content.min.css", // Static files path(step 2)
              }}
              onEditorChange={(newValue) => {
                setForm({
                  ...form,
                  description: newValue,
                });
              }}
            />

            <button className="submitButton" type="submit">
              수정 하기
            </button>
          </form>
        )}
      </div>
    </>
  );
};

export default FixedMenuCard;
