import React from "react";
import NavBarMenu from "./MainPageContents/NavBarComponent/NavBarMenu";
import { useParams } from "react-router-dom";
import "./NavBar.scss";

const NavBar = ({
  setSearchText,
  sideMenuData,
  projectInfoData,
  setFilterText,
  filterText,
  viewWidth,
  toggleMenuNav,
  toggleMenuNavCN,
  handleToggleFunction,
  totalContentsControl,
}) => {
  const params = useParams();

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const pageRefresh = () => {
    window.location.replace(`/${params.projectName}`);
  };

  return (
    <div className="navBarTotal">
      <div className="navBarTotalLeft">
        <img
          className="navBarLogo"
          src="/images/DTSLogo.png"
          alt="DTS"
          onClick={pageRefresh}
        />
        <input
          className="navBarInput"
          onChange={handleSearch}
          placeholder="Search..."
        />
      </div>
      <div className="navBarTotalRight">
        <img
          className="navBarTotalRightImg"
          src="/images/bars-solid.svg"
          alt="Menu"
          onClick={handleToggleFunction}
        />
      </div>
      {viewWidth < 992 && toggleMenuNav && (
        <div
          className={
            toggleMenuNavCN
              ? "navBarTotalRightContents"
              : "navBarTotalRightContents close"
          }
        >
          <NavBarMenu
            sideMenuData={sideMenuData}
            projectInfoData={projectInfoData}
            setFilterText={setFilterText}
            filterText={filterText}
            handleToggleFunction={handleToggleFunction}
            totalContentsControl={totalContentsControl}
          />
        </div>
      )}
    </div>
  );
};

export default NavBar;
