import React, { useEffect, useState } from "react";

import MainPageCategoryDetailLists from "./MainPageCategoryDetailLists";
import "./MainPageCategoryLists.scss";

const MainPageCategoryLists = ({
  menu,
  setFilterText,
  filterText,
  handleToggleFunction,
  totalContentsControl,
}) => {
  const [listToggle, setListToggle] = useState(false);
  const [listToggleCN, setListToggleCN] = useState(false);

  const handleToggle = () => {
    if (listToggle) {
      setListToggleCN(false);
      setTimeout(() => {
        setListToggle(false);
      }, 150);
    } else {
      setListToggle(true);
      setListToggleCN(true);
    }
  };

  useEffect(() => {
    if (!totalContentsControl) {
      setListToggleCN(false);
      setTimeout(() => {
        setListToggle(false);
      }, 150);
    } else {
      setListToggle(true);
      setListToggleCN(true);
    }
  }, [totalContentsControl]);

  return (
    <div className="mainPageCategoryListsBox">
      <div className="mainPageCategoryListTitle" onClick={handleToggle}>
        {menu.title}
      </div>
      {listToggle &&
        menu.menu.map((com, idx) => {
          return (
            <div
              className={
                listToggleCN
                  ? "mainPageCategoryDetail"
                  : "mainPageCategoryDetail close"
              }
              key={idx}
            >
              <MainPageCategoryDetailLists
                detailMenu={com}
                idx={idx}
                setFilterText={setFilterText}
                listToggle={listToggle}
                filterText={filterText}
                handleToggleFunction={handleToggleFunction}
                totalContentsControl={totalContentsControl}
              />
            </div>
          );
        })}
    </div>
  );
};

export default MainPageCategoryLists;
