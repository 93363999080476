import React from "react";
import MainPageCategoryLists from "../MainPageLeftComponets/MainPageCategoryLists";
import "./NavBarMenu.scss";

const NavBarMenu = ({
  sideMenuData,
  setFilterText,
  filterText,
  handleToggleFunction,
  totalContentsControl,
}) => {
  return (
    <div className="navBarMenu">
      <div className="mainPageCategory">
        {sideMenuData.map((com, idx) => {
          return (
            <MainPageCategoryLists
              key={idx}
              menu={com}
              setFilterText={setFilterText}
              filterText={filterText}
              handleToggleFunction={handleToggleFunction}
              totalContentsControl={totalContentsControl}
            />
          );
        })}
      </div>
    </div>
  );
};

export default NavBarMenu;
