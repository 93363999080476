import React from "react";
import FinalCategory from "./FinalCategory";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

import "./MiddleCategory.scss";

const MiddleCategory = ({
  tag,
  idx,
  index,
  testArr,
  setTestArr,
  categoryDetail,
  setCategoryDetail,
}) => {
  const handleCategoryTitle = (e) => {
    const { name, value } = e.target;

    categoryDetail[idx].menu[index][name] = value;
    setCategoryDetail([...categoryDetail]);
  };

  return (
    <div className="middleCategory">
      <FormControl fullWidth>
        <TextField
          id="outlined-basic"
          label={`${idx + 1}-${index + 1} Category`}
          variant="outlined"
          name="title"
          onChange={handleCategoryTitle}
          value={categoryDetail[idx].menu[index]["title"]}
          sx={{
            marginBottom: "10px",
          }}
        />
      </FormControl>

      <FinalCategory
        idx={idx}
        numindex={index}
        categoryDetail={categoryDetail}
        setCategoryDetail={setCategoryDetail}
      />
    </div>
  );
};

export default MiddleCategory;
