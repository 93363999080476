import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import url from "../../url/fetchUrl";
import MainPageLeft from "../../MainPage/Components/MainPageContents/MainPageLeft";
import DetailCategoryCard from "../../NewMenuCard/DetailCategoryCard";
import { useNavigate } from "react-router-dom";

import "../../NewMenuCard/NewCategory.scss";

const FixedCategory = () => {
  const navigate = useNavigate("");
  const [menuOption, setMenuOption] = useState([]);
  const [projectId, setProjectId] = useState("");
  const [newProjectInfo, setProjectInfo] = useState({
    projectName: "",
    clientLogo: "",
    clientImg: "",
  });

  const [categoryDetail, setCategoryDetail] = useState([
    {
      title: "",
      menu: [{ title: "", menu: [] }],
    },
  ]);

  const [deleteModal, setDeleteModal] = useState({
    state: false,
    parameter: "",
  });

  const [deleteBoolean, setDeleteBoolean] = useState(false);

  useEffect(() => {
    fetch(`${url}/projectInfo/`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setMenuOption(data.data);
      });
  }, []);

  const settingProject = (e) => {
    const { name, value } = e.target;

    setProjectInfo({
      ...newProjectInfo,
      [name]: value.toLowerCase(),
    });
  };

  const handleImageChange = (e) => {
    const { name, files } = e.target;
    const uploadFIle = files[0];

    const reader = new FileReader();
    reader.readAsDataURL(uploadFIle);
    reader.onloadend = () => {
      const base64 = reader.result;

      setProjectInfo({
        ...newProjectInfo,
        [name]: base64,
      });
    };
  };

  const addCategory = () => {
    const obj = {
      title: "",
      menu: [{ title: "", menu: [] }],
    };

    setCategoryDetail([...categoryDetail, obj]);
  };

  const saveCategory = () => {
    if (
      newProjectInfo.projectName.length > 0 &&
      newProjectInfo.clientLogo.length > 0 &&
      newProjectInfo.clientImg.length > 0 &&
      categoryDetail.length > 0
    ) {
      fetch(`${url}/projectInfo/fixedCate/${projectId}`, {
        method: "put",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          projectName: newProjectInfo.projectName,
          clientLogo: newProjectInfo.clientLogo,
          clientImg: newProjectInfo.clientImg,
          data: categoryDetail,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success === true) {
            alert("수정이 완료 되었습니다.");
          }
        });
    } else {
      alert("프로젝트 정보 및 메뉴바를 입력하세요");
      navigate(`/newCard/${newProjectInfo.projectName}`);
    }
  };

  const deleteCategory = (arrIndex) => {
    categoryDetail.splice(arrIndex, 1);
    setCategoryDetail([...categoryDetail]);
  };

  const handleMenuCard = (cateInfo) => {
    setProjectId(cateInfo._id);

    fetch(`${url}/projectInfo/fixedCate/${cateInfo._id}`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setProjectInfo({
          projectName: data.data.projectName,
          clientLogo: data.data.clientLogo,
          clientImg: data.data.clientImg,
        });
        setCategoryDetail(data.data.data);
      });
  };

  const deleteCard = (cardData) => {
    setDeleteModal({ ...deleteModal, state: true, parameter: cardData });
  };

  const closeModal = () => {
    setDeleteModal({ ...deleteModal, state: false, parameter: "" });
  };

  const deleteData = (cardData) => {
    fetch(`${url}/projectInfo/fixedCate/${cardData._id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          fetch(
            `${url}/projectManual/fixedCard/allDelete/${cardData.projectName}`,
            {
              method: "DELETE",
            }
          )
            .then((res) => res.json())
            .then((data) => {
              if (data.success === true) {
                alert("삭제 완료됐습니다.");
                window.location.replace(`/fixedCate`);
              }
            });
        }
      });
  };

  const handleDeleteText = (e) => {
    if (e.target.value === "지우면 후회할텐데") {
      setDeleteBoolean(true);
    } else {
      setDeleteBoolean(false);
    }
  };

  return (
    <div className="makeManualCate">
      {deleteModal.state && (
        <div className="deleteModal">
          <div className="deleteModalContent">
            <div className="deleteModalContentTitle">
              정말 삭제 하시겠습니까? <br />
              아래에 "지우면 후회할텐데"를 입력하여 주세요.
            </div>
            <input onChange={handleDeleteText} />
            <div className="deleteModalContentButtonBox">
              <button
                className="deleteModalContentButtonCancle"
                onClick={closeModal}
              >
                취소
              </button>
              <button
                className="deleteModalContentButtonDelete"
                onClick={() => {
                  deleteData(deleteModal.parameter);
                }}
                disabled={!deleteBoolean}
              >
                삭제
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="selectBox">
        <h3 className="selectBoxTitle">
          변경하고자하는 프로젝트를 선택해주세요.
        </h3>

        {menuOption.length > 0 &&
          menuOption.map((com, idx) => {
            return (
              <div
                className="buttonBoxDelete"
                key={idx}
                style={{
                  margin: "10px 0",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  variant="outlined"
                  className="selectButton"
                  onClick={() => handleMenuCard(com)}
                  sx={{
                    color: "black",
                    border: "1px solid rgb(118, 118, 118)",
                    width: "80%",
                  }}
                >
                  {com.projectName}
                </Button>

                <Button
                  variant="outlined"
                  className="selectDeleteButton"
                  onClick={() => deleteCard(com)}
                  sx={{
                    color: "black",
                    border: "1px solid rgb(118, 118, 118)",
                    width: "15%",
                  }}
                >
                  삭제
                </Button>
              </div>
            );
          })}
      </div>
      {newProjectInfo.projectName.length > 0 && (
        <div className="newCategoryTotal">
          <div className="newCategoryBox">
            <div className="projectInfoBox">
              <div className="projectInformTitle">Project Information</div>
              <div className="projectInformBox">
                <FormControl fullWidth>
                  <TextField
                    id="outlined-basic"
                    label="Project Name"
                    variant="outlined"
                    name="projectName"
                    onChange={settingProject}
                    value={newProjectInfo.projectName}
                  />
                </FormControl>
              </div>
              <div className="projectInformBox BottonBox">
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="raised-button-file"
                  type="file"
                  name="clientLogo"
                  onChange={handleImageChange}
                />
                <label htmlFor="raised-button-file">
                  <Button
                    variant="outlined"
                    component="span"
                    sx={{
                      color: "black",
                      border: "1px solid rgb(118, 118, 118)",
                      padding: "15px",
                    }}
                  >
                    Client CI Image
                  </Button>
                </label>

                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="raised-button-file"
                  name="clientImg"
                  onChange={handleImageChange}
                />
                <label htmlFor="raised-button-file">
                  <Button
                    variant="outlined"
                    component="span"
                    sx={{
                      color: "black",
                      border: "1px solid rgb(118, 118, 118)",
                      padding: "15px",
                    }}
                  >
                    Client Banner Image
                  </Button>
                </label>
              </div>
            </div>
            <div className="categoryInputBox">
              <div className="categoryInput">Category Input</div>

              {categoryDetail.map((com, idx) => {
                return (
                  <div className="detailCategoryCardBox" key={idx}>
                    <DetailCategoryCard
                      data={com}
                      idx={idx}
                      categoryDetail={categoryDetail}
                      setCategoryDetail={setCategoryDetail}
                    />
                    <FormControl fullWidth>
                      <Button
                        variant="outlined"
                        onClick={() => deleteCategory(idx)}
                        sx={{
                          color: "black",
                          border: "1px solid rgb(118, 118, 118)",
                        }}
                      >
                        메인 메뉴 카테고리 삭제
                      </Button>
                    </FormControl>
                  </div>
                );
              })}
              <Button
                variant="outlined"
                onClick={addCategory}
                sx={{
                  color: "black",
                  border: "1px solid rgb(118, 118, 118)",
                  marginBottom: "10px",
                }}
              >
                메인 메뉴 카테고리 추가
              </Button>
              <Button
                variant="outlined"
                onClick={saveCategory}
                sx={{
                  color: "black",
                  border: "1px solid rgb(118, 118, 118)",
                }}
              >
                저장
              </Button>
            </div>
          </div>

          {categoryDetail && newProjectInfo && (
            <div className="createMainPageLeft">
              <MainPageLeft
                sideMenuData={categoryDetail}
                projectInfoData={newProjectInfo}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FixedCategory;
