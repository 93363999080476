import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainPage from "./MainPage/MainPage";
import NewCategory from "./NewMenuCard/NewCategory";
import NewMenuCard from "./NewMenuCard/NewCard/NewMenuCard";
import FixedMenuCard from "./FixedPage/FixedCard/FixedMenuCard";
import FixedCategory from "./FixedPage/FixedCategory/FixedCategory";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:projectName" element={<MainPage />} />
        <Route path="/new" element={<NewCategory />} />
        <Route path="/newCard/:projectName" element={<NewMenuCard />} />
        <Route path="/fixedCate/" element={<FixedCategory />} />
        <Route path="/fixedCard/:projectName" element={<FixedMenuCard />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
