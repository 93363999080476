import React, { useEffect, useState } from "react";
import useViewPort from "../Hooks/useViewPort";
import NavBar from "./Components/NavBar";
import MainPageLeft from "./Components/MainPageContents/MainPageLeft";
import MainPageRight from "./Components/MainPageContents/MainPageRight";
import { useParams } from "react-router-dom";
import url from "../url/fetchUrl";
import "./MainPage.scss";

const MainPage = () => {
  const [sortingArr, setSortingArr] = useState([]);
  const [sideMenuData, setSideMenuData] = useState();
  const [filterText, setFilterText] = useState("");
  const [projectInfoData, setProjectInfoData] = useState();
  const [searchText, setSearchText] = useState("");

  const [toggleMenuNav, setToggleMenuNav] = useState(false);
  const [toggleMenuNavCN, setToggleMenuNavCN] = useState(false);

  const [totalContentsControl, setTotalContentsControl] = useState(false);

  const { width } = useViewPort();
  const params = useParams();

  useEffect(() => {
    fetch(`${url}/projectInfo/common`)
      .then((res) => res.json())
      .then((data1) => {
        const commonData = data1.data.data;

        fetch(`${url}/projectInfo/${params.projectName}`, {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then((res) => res.json())
          .then((data) => {
            setProjectInfoData({
              clientLogo: data.data.clientLogo,
              clientImg: data.data.clientImg,
            });

            const projectData = data.data.data;

            let categoryDate = commonData.concat(projectData);

            setSideMenuData(categoryDate);

            let categoryDateArr = [];

            categoryDate.map((com) =>
              com.menu.map((com2) =>
                com2.menu.length === 0
                  ? categoryDateArr.push(com2.title)
                  : com2.menu.map((com3) => categoryDateArr.push(com3))
              )
            );

            setSortingArr(categoryDateArr);
          });
      });
  }, [params]);

  const handleToggleFunction = () => {
    if (toggleMenuNav) {
      setToggleMenuNavCN(false);
      setTimeout(() => {
        setToggleMenuNav(false);
      }, 150);
    } else {
      setToggleMenuNav(true);
      setToggleMenuNavCN(true);
    }
  };

  useEffect(() => {
    setToggleMenuNavCN(false);
    setTimeout(() => {
      setToggleMenuNav(false);
    }, 150);
  }, [width]);

  return (
    <div className="mainPageTotal">
      <NavBar
        viewWidth={width}
        setSearchText={setSearchText}
        sideMenuData={sideMenuData}
        projectInfoData={projectInfoData}
        setFilterText={setFilterText}
        filterText={filterText}
        toggleMenuNav={toggleMenuNav}
        toggleMenuNavCN={toggleMenuNavCN}
        handleToggleFunction={handleToggleFunction}
        totalContentsControl={totalContentsControl}
      />
      <div className="mainPageContentsBox">
        {sideMenuData && projectInfoData && (
          <>
            <div className="mainPageLeftBox">
              <MainPageLeft
                sideMenuData={sideMenuData}
                projectInfoData={projectInfoData}
                setFilterText={setFilterText}
                filterText={filterText}
                handleToggleFunction={handleToggleFunction}
                totalContentsControl={totalContentsControl}
                setTotalContentsControl={setTotalContentsControl}
              />
            </div>
            <div className="mainPageRightBox">
              <MainPageRight
                projectInfoData={projectInfoData}
                searchText={searchText}
                filterText={filterText}
                sortingArr={sortingArr}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MainPage;
