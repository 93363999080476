import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import "./FinalCategory.scss";

const FinalCategory = ({
  idx,
  numindex,
  categoryDetail,
  setCategoryDetail,
}) => {
  const [tagText, setTagText] = useState("");
  const [tagSet, setTagSet] = useState(
    new Set(categoryDetail[idx]["menu"][numindex]["menu"])
  );

  const handleTag = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      if (tagText.length > 0) {
        tagSet.add(tagText);
        setTagSet(tagSet);
        setTagText("");
        categoryDetail[idx]["menu"][numindex]["menu"] = [...tagSet];
        setCategoryDetail([...categoryDetail]);
      }
    }
  };

  const deleteTag = (tag) => {
    tagSet.delete(tag);
    setTagSet(new Set(tagSet));
    categoryDetail[idx]["menu"][numindex]["menu"] = [...tagSet];
    setCategoryDetail([...categoryDetail]);
  };

  const handleTagAdd = (e) => {
    const { value } = e.target;
    setTagText(value);
  };

  return (
    <div className="finalCategory">
      <FormControl fullWidth>
        <TextField
          id="outlined-basic"
          label={`${idx + 1}-${numindex + 1} Detail Menu`}
          variant="outlined"
          value={tagText}
          onKeyUp={handleTag}
          onChange={handleTagAdd}
          sx={{
            marginBottom: "10px",
          }}
        />
      </FormControl>
      {[...tagSet].map((tag, idx) => {
        return (
          <div className="blogTagContents" key={idx}>
            <div
              onClick={() => {
                deleteTag(tag);
              }}
            >
              {tag}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FinalCategory;
