import React, { useState, useEffect } from "react";
import "./MainPageCategoryDetailLists.scss";

const MainPageCategoryDetailLists = ({
  detailMenu,
  setFilterText,
  filterText,
  handleToggleFunction,
  totalContentsControl,
}) => {
  const [listToggle, setListToggle] = useState(false);
  const [listToggleCN, setListToggleCN] = useState(false);

  const handleListToggle = (menu) => {
    if (menu.length === 0) {
      setFilterText(detailMenu.title);
      handleToggleFunction();
      if (filterText === detailMenu.title) {
        setFilterText("");
        handleToggleFunction();
      }
    } else {
      if (listToggle) {
        setListToggleCN(false);
        setTimeout(() => {
          setListToggle(false);
        }, 150);
      } else {
        setListToggle(true);
        setListToggleCN(true);
      }
    }
  };

  useEffect(() => {
    if (!totalContentsControl) {
      setListToggleCN(false);
      setTimeout(() => {
        setListToggle(false);
      }, 150);
    } else {
      setListToggle(true);
      setListToggleCN(true);
    }
  }, [totalContentsControl]);

  const handleDetailList = (menu) => {
    if (filterText === menu) {
      setFilterText("");
      handleToggleFunction();
    } else {
      setFilterText(menu);
      handleToggleFunction();
    }
  };
  
  

  return (
    <div className="mainPageCategoryDetailListsBox">
      <div
        className={filterText === detailMenu.title ? "mainPageCategoryDetailListTitle on" : "mainPageCategoryDetailListTitle"}
        onClick={() => {
          handleListToggle(detailMenu.menu);
        }}        
      >
        {detailMenu.title}
      </div>
      {listToggle &&
        detailMenu.menu.map((com, idx) => {
          return (
            <div
              className={
                filterText === com ? "mainPageCategoryDetailListsDetail on" : 
                listToggleCN
                  ? "mainPageCategoryDetailListsDetail"
                  : "mainPageCategoryDetailListsDetail close"
              }
              key={idx}
              onClick={() => {
                handleDetailList(com);
              }}              
            >
              {com}
            </div>
          );
        })}
    </div>
  );
};

export default MainPageCategoryDetailLists;
