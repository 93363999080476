import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Pagination from "react-js-pagination";
import ReactLoading from "react-loading";
import styled from "styled-components";
import MainPageContentCard from "./MainPageRightComponents/MainPageContentCard";
import url from "../../../url/fetchUrl";
import "./MainPageRight.scss";


const MainPageRight = ({
  searchText,
  filterText,
  projectInfoData,
  sortingArr,
}) => {
  const [page, setPage] = useState(1);
  const [originalpage, setOriginalPage] = useState(1);
  const [items, setItems] = useState(5);
  const [menuCards, setMenuCards] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const params = useParams();

  useEffect(() => {
    fetch(`${url}/projectManual/common`)
      .then((res) => res.json())
      .then((data1) => {
        const commonData = data1.data.sort(function (a, b) {
          return a.number - b.number;
        });

        fetch(`${url}/projectManual/${params.projectName}`)
          .then((res) => res.json())
          .then((data) => {
            const projectData = data.data.sort(function (a, b) {
              return a.number - b.number;
            });

            const totalData = commonData.concat(projectData);

            const sortingData = [];

            sortingArr.map((com) => {
              return totalData.map((com2) => {
                return com === com2.categoryName && sortingData.push(com2);
              });
            });

            // setMenuCards(
            //   sortingData.filter((x) => x.categoryName.includes(filterText))
            // );

            setMenuCards(sortingData);

            setOriginalData(sortingData);
          });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (searchText.length === 0) {
      setPage(originalpage);
    } else {
      setPage(1);
    }
    setMenuCards(originalData.filter((x) => x.title.includes(searchText)));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    if (filterText.length === 0) {
      setPage(originalpage);
    } else {
      setPage(1);
    }
    setMenuCards(
      originalData.filter((x) => x.categoryName.includes(filterText))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText]);

  const handlePageChange = (page) => {
    setPage(page);
    setOriginalPage(page);
  };

  const itemChange = (e) => {
    setItems(Number(e.target.value));
    setPage(1);
  };

  return (
    <div className="mainPageRightContents">
      <img
        className="mainPageClientMain"
        src={projectInfoData.clientImg}
        alt="Client Logo"
      />
      {menuCards.length > 0 ?
        menuCards
          .slice(items * (page - 1), items * (page - 1) + items)
          .map((com, idx) => {
            return <MainPageContentCard key={idx} data={com} page={page} filterText={filterText}/>;
          }) : 
          <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "40px",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={"#00000"}
            height={"100%"}
            width={"100%"}
          />
          <h3>로딩중</h3>
        </div>}
        {menuCards.length > 0 && 
        <>
          <PaginationBox>
            <Pagination
              activePage={page}
              itemsCountPerPage={items}
              totalItemsCount={menuCards.length}
              pageRangeDisplayed={5}
              prevPageText="<"
              nextPageText=">"
              firstPageText="<<"
              lastPageText=">>"
              onChange={handlePageChange}
            ></Pagination>
          </PaginationBox>

          <select
            className="mainPageRightContentsSelect"
            value={items}
            name="items"
            onChange={itemChange}
          >
            <option value="5">5개</option>
            <option value="10">10개</option>
            <option value="15">15개</option>
            <option value="20">20개</option>
          </select>
        </>
      }
    </div> 
  );
};

export default MainPageRight;

const PaginationBox = styled.div`
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
  ul {
    list-style: none;
    padding: 0;
  }
  ul.pagination li {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 1px solid #e2e2e2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    background-color: white;
  }
  ul.pagination li:first-child {
    border-radius: 5px 0 0 5px;
  }
  ul.pagination li:last-child {
    border-radius: 0 5px 5px 0;
  }
  ul.pagination li a {
    text-decoration: none;
    color: black;
    font-size: 1rem;
  }
  ul.pagination li.active a {
    color: white;
  }
  ul.pagination li.active {
    background-color: #337ab7;
  }
  ul.pagination li a:hover,
  ul.pagination li a.active {
    color: blue;
  }
`;
