import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import MainPageLeft from "../MainPage/Components/MainPageContents/MainPageLeft";
import DetailCategoryCard from "./DetailCategoryCard";
import { useNavigate } from "react-router-dom";

import url from "../url/fetchUrl";
import "./NewCategory.scss";

const NewCategory = () => {
  const [newProjectInfo, setProjectInfo] = useState({
    projectName: "",
    clientLogo: "",
    clientImg: "",
  });

  const [categoryDetail, setCategoryDetail] = useState([
    {
      title: "",
      menu: [{ title: "", menu: [] }],
    },
  ]);

  const [menuOption, setMenuOption] = useState([]);

  const [projectNameCheck, setProjectNameCheck] = useState([]);

  useEffect(() => {
    fetch(`${url}/projectInfo/`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setMenuOption(data.data);

        const projectNames = data.data.map((com) => com.projectName);

        setProjectNameCheck(projectNames);
      });
  }, []);

  const navigate = useNavigate("");

  const settingProject = (e) => {
    const { name, value } = e.target;

    console.log(value);

    setProjectInfo({
      ...newProjectInfo,
      [name]: value.toLowerCase(),
    });
  };

  const handleImageChange = (e) => {
    const { name, files } = e.target;
    const uploadFIle = files[0];

    const reader = new FileReader();
    reader.readAsDataURL(uploadFIle);
    reader.onloadend = () => {
      const base64 = reader.result;

      setProjectInfo({
        ...newProjectInfo,
        [name]: base64,
      });
    };
  };

  const addCategory = () => {
    const obj = {
      title: "",
      menu: [{ title: "", menu: [] }],
    };

    setCategoryDetail([...categoryDetail, obj]);
  };

  const saveCategory = () => {
    if (
      newProjectInfo.projectName.length > 0 &&
      newProjectInfo.clientLogo.length > 0 &&
      newProjectInfo.clientImg.length > 0 &&
      categoryDetail.length > 0
    ) {
      if (projectNameCheck.includes(newProjectInfo.projectName)) {
        alert("Project Name이 중복됩니다.");
      } else {
        fetch(`${url}/projectInfo`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            projectName: newProjectInfo.projectName,
            clientLogo: newProjectInfo.clientLogo,
            clientImg: newProjectInfo.clientImg,
            data: categoryDetail,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.success === true) {
              navigate(`/newCard/${newProjectInfo.projectName}`);
            }
          });
      }
    } else {
      alert("프로젝트 정보 및 메뉴바를 입력하세요");
    }
  };

  const deleteCategory = (arrIndex) => {
    categoryDetail.splice(arrIndex, 1);
    setCategoryDetail([...categoryDetail]);
  };

  const handleMenuCard = async (cateInfo) => {
    const fetch_project_cate = await fetch(
      `${url}/projectInfo/fixedCate/${cateInfo}`,
      {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) => res.json());

    const fetch_project_cate_result = fetch_project_cate;

    setCategoryDetail(fetch_project_cate_result.data.data);
    setProjectNameCheck(fetch_project_cate_result.data.projectName);
  };

  const [selectValue, setSelectValue] = React.useState({
    _id: "",
    projectName: "",
  });

  const handleChange = (event) => {
    const find_obj = menuOption.find((com) => com._id === event.target.value);

    setSelectValue((prev) => ({ ...prev, ...find_obj }));

    handleMenuCard(event.target.value);
  };

  return (
    <div className="makeManualCate">
      <div className="selectBox">
        <h3 className="selectBoxTitle">
          복사하고자 하는 프로젝트를 선택해주세요.(선택사항)
        </h3>
        <div className="buttonBox">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Copy Project Selector
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectValue._id}
              label="Copy Project Selector"
              onChange={handleChange}
            >
              {menuOption.map((com, idx) => {
                return (
                  <MenuItem key={idx} value={com._id} name={com.projectName}>
                    {com.projectName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
      </div>

      <div className="newCategoryTotal">
        <div className="newCategoryBox">
          <div className="projectInfoBox">
            <div className="projectInformTitle">Project Information</div>
            <div className="projectInformBox">
              <FormControl fullWidth>
                <TextField
                  id="outlined-basic"
                  label="Project Name"
                  variant="outlined"
                  name="projectName"
                  onChange={settingProject}
                  value={newProjectInfo.projectName}
                />
              </FormControl>
              {/* <div className="projectInformBoxTitle"></div>
              <input
                className="createInput"
                type="text"
                name="projectName"
                onChange={settingProject}
                value={newProjectInfo.projectName}
              /> */}
            </div>
            <div className="projectInformBox BottonBox">
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="raised-button-file"
                type="file"
                name="clientLogo"
                onChange={handleImageChange}
              />
              <label htmlFor="raised-button-file">
                <Button
                  variant="outlined"
                  component="span"
                  sx={{
                    color: "black",
                    border: "1px solid rgb(118, 118, 118)",
                    padding: "15px",
                  }}
                >
                  Client CI Image
                </Button>
              </label>

              <input
                accept="image/*"
                style={{ display: "none" }}
                id="raised-button-file"
                name="clientImg"
                onChange={handleImageChange}
              />
              <label htmlFor="raised-button-file">
                <Button
                  variant="outlined"
                  component="span"
                  sx={{
                    color: "black",
                    border: "1px solid rgb(118, 118, 118)",
                    padding: "15px",
                  }}
                >
                  Client Banner Image
                </Button>
              </label>
            </div>
          </div>
          <div className="categoryInputBox">
            <div className="categoryInput">Category Input</div>

            {categoryDetail.map((com, idx) => {
              return (
                <div className="detailCategoryCardBox" key={idx}>
                  <DetailCategoryCard
                    data={com}
                    idx={idx}
                    categoryDetail={categoryDetail}
                    setCategoryDetail={setCategoryDetail}
                  />
                  <FormControl fullWidth>
                    <Button
                      variant="outlined"
                      onClick={() => deleteCategory(idx)}
                      sx={{
                        color: "black",
                        border: "1px solid rgb(118, 118, 118)",
                      }}
                    >
                      메인 메뉴 카테고리 삭제
                    </Button>
                  </FormControl>
                </div>
              );
            })}

            <Button
              variant="outlined"
              onClick={addCategory}
              sx={{
                color: "black",
                border: "1px solid rgb(118, 118, 118)",
                marginBottom: "10px",
              }}
            >
              메인 메뉴 카테고리 추가
            </Button>
            <Button
              variant="outlined"
              onClick={saveCategory}
              sx={{
                color: "black",
                border: "1px solid rgb(118, 118, 118)",
              }}
            >
              저장
            </Button>
          </div>
        </div>

        {categoryDetail && newProjectInfo && (
          <div className="createMainPageLeft">
            <MainPageLeft
              sideMenuData={categoryDetail}
              projectInfoData={newProjectInfo}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default NewCategory;
